* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
    font-family: 'Metropolis';
    border: medium none;
    margin: 0;
    padding: 0;
}

:focus {
    outline: none !important;
    border: none !important;
    box-shadow: unset !important;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraBold.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraBold.woff') format('woff'),
        url('../fonts/Metropolis-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Bold.woff2') format('woff2'),
        url('../fonts/Metropolis-Bold.woff') format('woff'),
        url('../fonts/Metropolis-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Black.woff2') format('woff2'),
        url('../fonts/Metropolis-Black.woff') format('woff'),
        url('../fonts/Metropolis-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-BlackItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-BlackItalic.woff') format('woff'),
        url('../fonts/Metropolis-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-LightItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-LightItalic.woff') format('woff'),
        url('../fonts/Metropolis-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Semi';
    src: url('../fonts/Metropolis-SemiBold.woff2') format('woff2'),
        url('../fonts/Metropolis-SemiBold.woff') format('woff'),
        url('../fonts/Metropolis-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-ThinItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-ThinItalic.woff') format('woff'),
        url('../fonts/Metropolis-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Regular.woff2') format('woff2'),
        url('../fonts/Metropolis-Regular.woff') format('woff'),
        url('../fonts/Metropolis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Medium.woff2') format('woff2'),
        url('../fonts/Metropolis-Medium.woff') format('woff'),
        url('../fonts/Metropolis-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/Metropolis-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraLight.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraLight.woff') format('woff'),
        url('../fonts/Metropolis-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Thin.woff2') format('woff2'),
        url('../fonts/Metropolis-Thin.woff') format('woff'),
        url('../fonts/Metropolis-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-BoldItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-BoldItalic.woff') format('woff'),
        url('../fonts/Metropolis-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Semi';
    src: url('../fonts/Metropolis-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-SemiBoldItalic.woff') format('woff'),
        url('../fonts/Metropolis-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-RegularItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-RegularItalic.woff') format('woff'),
        url('../fonts/Metropolis-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-MediumItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-MediumItalic.woff') format('woff'),
        url('../fonts/Metropolis-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraLightItalic.woff') format('woff'),
        url('../fonts/Metropolis-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Light.woff2') format('woff2'),
        url('../fonts/Metropolis-Light.woff') format('woff'),
        url('../fonts/Metropolis-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}



h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Metropolis';
}

h1 {
    font-family: 'Metropolis';
    font-family: 'Metropolis';
}

h2 {
    font-family: 'Metropolis';
}

h3 {
    font-family: 'Metropolis';
}

p {}

h4 {
    font-family: 'Metropolis';
    font-size: 18px;
}

img,
fieldset {
    border: 0 none;
}

a {
    font-family: 'Metropolis';
    border: none;
    outline: none;
}

input[type="submit"],
select {
    font-family: 'Metropolis';
    -webkit-appearance: none;
}

img {
    font-family: 'Metropolis';
    border: none;
    height: auto;
    max-width: 100%;
    outline: none;
    vertical-align: middle;
}

iframe {
    font-family: 'Metropolis';
    vertical-align: middle;
}

.left-side-img img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    margin-left: -12px;
}

.right-side-form h1 {
    color: #0D132C;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.right-side-form img {
    margin-bottom: 30px;
}

.right-side-form .nav-tabs {
    border: none;
    margin: 24px 0 30px 0;
}

.right-side-form .nav-tabs .nav-link.active {
    border-radius: 24px;
    border: 1px solid #F1F7FF;
    background: #1037FD;
    color: #fff;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 9px 37px;
}

.right-side-form .nav-tabs .nav-link {
    border-radius: 24px;
    border: 1px solid #F1F7FF;
    background: #E8F0F8;
    color: #1037FD;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 20px;
    padding: 9px 37px;
}

.right-side-form {
    padding: 24px 50px;
}

.right-side-form .form-label {
    color: #0D132C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.right-side-form .form-control,
.right-side-form .form-select {
    border-radius: 24px;
    border: 1px solid #F1F7FF;
    background: #E8F0F8;
    padding: 13px 14px;
}

.right-side-form .form-control::placeholder {
    color: #1F253C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.eye-icon {
    position: absolute;
    top: 44px;
    right: 17px;
    cursor: pointer;
    margin: 0 !important;
    width: 20px;
}

.forgot-password {
    color: #D10202;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    display: block;
    margin-top: 21px;
}

.submit-btn.btn.btn-primary {
    border-radius: 24px;
    border: 1px solid #F1F7FF;
    background: #DDEAFD;
    box-shadow: 0px 0px 16px 0px #0023D3 !important;
    color: #1037FD;
    text-align: center;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 47px;
    margin-top: 30px;
}

.forgot-form {
    margin-top: 24px;
}

.confirmation-outer-modal .modal-content {
    border-radius: 20px;
    background: #FFF;
    padding: 11px;
}

.confirmation-details h2 {
    color: #1037FD;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    margin-top: 21px;
}

.confirmation-details p {
    color: #0D132C;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-top: 9px;
}

.border-0.close-btns.modal-header {
    position: absolute;
    right: 12px;
    z-index: 99;
}

.left-side-img.register-left-side img {
    height: 100%;
}

.hero-section {
    background: url("../images/hero-bg.png") no-repeat;
    background-position: bottom;
    background-size: cover;
    width: 100%;
    height: 87vh;
    display: flex;
    align-items: center;
    position: relative;
}

.creator-bg {
    background: url("../images/creator-bg.jpg") no-repeat;
    background-position: bottom;
    background-size: cover;
    background-position: bottom;
    background-size: cover;
    width: 100%;
    position: relative;
    padding: 153px 0 629px 0;
}

.creator-bg.brand-bg {
    background: url("../images/brand-bg.png") no-repeat;
    background-size: cover;
    background-position: bottom center;
}

.assets-1 {
    position: absolute;
    right: 15%;
    top: 16%;
}

.assets-2 {
    position: absolute;
    top: 27%;
    left: 10%;
}

.hero-content h1 {
    color: #FFF;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 79px;
}

.hero-content h1 span {
    color: #FFF;
    font-size: 70px;
    font-style: italic;
    font-weight: 800;
    line-height: 79px;
}

.hero-content p {
    color: #DDD;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 27px;
    margin: 13px 0;
}

.explore-btns.btn.btn-primary {
    border-radius: 24px;
    border: 1px solid #F1F7FF;
    background: #DDEAFD;
    box-shadow: 0px 0px 6px 0px #FFF;
    color: #1037FD;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 14px 21px;
    margin-top: 11px;
}

.scroll-icon {
    position: absolute;
    left: 50%;
    bottom: 19px;
    transform: translate(-50%, 0);
    cursor: pointer;
}

.about-section {
    padding: 80px 0;
    background: #FFF6F6;
}
.about-content h2 span {
    color: #1037FD;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    background: transparent;
    padding: 0;
    margin: 0;
}
.about-content span {
    border-radius: 29px;
    background: #D9E2FF;
    color: #1037FD;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 10px;
    display: inline-block;
    margin-bottom: 13px;
}

.about-content h2 {
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 21px;
}

.creator-box {
    border-radius: 12px;
    background: #E8F0F8;
    box-shadow: 0px 0px 8px 0px #8DBDFF;
    padding: 21px;
    margin-bottom: 23px;
    height: 100%;
}
.about-content .col-md-6 {
    margin-bottom: 21px;
}

.creator-box.second-box {
    background: #FFF;
    box-shadow: unset;
}

.creator-box h4 {
    color: #0D132C;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 12px 0;
}

.creator-box p {
    color: #1F253C;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.about-content {
    padding: 0 0 0 20px;
}

.about-section.about-brand {
    background: #fff;
}

.social-section {
    background: url("../images/social.png") no-repeat;
    background-size: cover;
    padding: 230px 0;
    background-position: center;
    position: relative;
}

.social-section h2 {
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    padding: 0 112px;
    margin-bottom: 12px;
}

.facebook-icon {
    position: absolute;
    right: 20%;
}

.youtube-icon {
    position: absolute;
    right: 56%;
    bottom: 3%;
}

.instagram-icon {
    position: absolute;
    left: 10%;
    top: 20%;
}

header .nav-link,
header .nav-link:hover,
header .nav-link:active,
header .nav-link:focus {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

header .contact-us-btn.btn.btn-primary {
    border-radius: 24px;
    border: 1px solid #F1F7FF;
    background: #DDEAFD;
    box-shadow: 0px 0px 16px 0px #0023D3;
    color: #1037FD;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 22px;
    padding: 9px 18px;
}

.contact-us-btn.btn.btn-primary svg {
    margin-left: 11px;
}

header .login-text.nav-link {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
}

header {
    position: absolute;
    top: 0;
    z-index: 9;
    width: 100%;
}

header .navbar-nav .nav-link.active {
    color: #fff !important;
    font-weight: 700;
}

.footer-content a {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    cursor: pointer;
    margin: 0 12px;
    text-decoration: none;
}

footer {
    background: #1037FD;
    padding: 52px 0;
}

.creator-bg h1 {
    color: #FFF;
    text-align: center;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}

section.creator-bg h3 {
    color: #FFF;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 157%;
}

.creator-cnt {
    text-align: center;
}

.community-section {
    padding: 114px 0;
    background: #FFF6F6;
}

.community-section h2 {
    color: #0D132C;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    margin-bottom: 40px;
}

.join-text {
    color: #1037FD;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
}

.join-text-under {
    color: #1037FD;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
}

.under-cmny p {
    color: #1F253C;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}

.under-cmny h3 span {
    color: #0D132C;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 43px;
}

.under-cmny h3 {
    color: #1037FD;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 13px;
    line-height: 43px;
}

.under-cmny img {
    height: 336px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 29px;
}

.community-section .col-md-6 {
    margin-bottom: 22px;
}

.under-cmny {
    border-radius: 12px;
    background: #E8F0F8;
    padding: 31px;
    margin-bottom: 25px;
    height: 100%;
}

.community-section.performance-section {
    padding: 80px 0;
    background: #fff;
}

.performace-inner {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22);
    padding: 12px;
    margin-bottom: 23px;
}

.performace-inner p {
    color: #1F253C;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.performace-inner h4 {
    color: #0D132C;
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 12px 0;
}

.performace-inner span {
    color: #11DA2F;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
    text-align: center;
}

.community-section.performance-section h5 {
    color: #1F253C;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 21px 0 25px 0;
}

.login-section.creator-register-section {
    background: #FFF6F6;
    position: relative;
}

.red-error {
    color: #D21010;
}

.register-text {
    color: #1037FD;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 20px 0;
}

.launch-section {
    background: url("../images/launch-bg.png") no-repeat;
    background-size: cover;
    background-position: top;
    padding: 22px 0;
    background-attachment: fixed;
}

.right-side-form.under-launch-form {
    background: #fff;
    padding: 30px;
    border-radius: 31px;
}

.coming-soon span {
    color: #fff;
    display: block;
    margin: 24px 0px 6px 0;
    font-size: 19px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    padding: 0 0 0 62px;
}

.coming-soon h1 {
    font-size: 70px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -3px;
}

.coming-soon h2 {
    font-size: 42px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: -2px;
    font-weight: 600;
}

.coming-soon span:after {
    content: "";
    position: absolute;
    width: 62px;
    height: 4px;
    background: #fff;
    left: -9px;
    top: 9px;
}

.marketplace-section {
    background-image: url("../images/marketplace-bg.png");
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
section.marketplace-listing .col-lg-4.col-md-6.col-sm-12 {
    margin-bottom: 24px;
}

.marketplace-box {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.12);
    padding: 20px;
    margin: 12px 0;
    cursor: pointer;
    height: 100%;
}

.marketplace-content h6 {
    color: #0D132C;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px;
}

.marketplace-content p img {
    margin-right: 8px;
    border-radius: unset;
    width: 20px;
    min-width: 20px;
    height: 20px;
}

.marketplace-content p {
    color: #606779;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    display: flex;
    align-items: center;
    padding: 4px 0;
}

.marketplace-content {
    margin-top: 15px;
}

.marketplace-box img {
    border-radius: 8px;
    width: 100%;
    height: 242px;
    object-fit: cover;
}

.filter-box-inner ul .nav-link {
    border-radius: 24px;
    border: 1px solid #F1F7FF;
    background: #E8F0F8;
    box-shadow: 0px 0px 6px 0px #FFF;
    color: #1037FD;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 33px;
    margin: 0 15px 0 0;
}

.filter-box-inner .nav-tabs .nav-item.show .nav-link,
.filter-box-inner .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #1037FD;
    font-size: 15px;
    font-weight: 400;
}

.filter-box-inner {
    border-radius: 12px;
    background: #F1F7FF;
    padding: 30px;
}

.filter-box {
    margin-top: -65px;
}

.filter-box-inner ul {
    border-bottom: none;
}

.search-brand input {
    border-radius: 24px;
    border: 1px solid #1F253C;
    box-shadow: 0px 0px 6px 0px #FFF;
    padding: 8px 40px 8px 15px;
    background: #F1F7FF;
}

.search-brand input::placeholder {
    font-size: 14px;
    color: #1F253C;
}

.search-brand .form-control:focus {
    background-color: #F1F7FF;
    border-color: #1F253C !important;
    box-shadow: unset;
    border: 1px solid gray !important   ;
}

.search-brand svg {
    position: absolute;
    right: 15px;
    bottom: 12px;
    cursor: pointer;
}
.trash-area input {
    width: 98%;
}
.min-height {
    min-height: calc(100vh - 184px);
}
.no-data-found-section h4 {
    font-size: 20px;
    font-weight: 700;
}
.no-data-found-section {
    text-align: center;
}
.no-data-found-section img {
    filter: opacity(0.5);
    width: 125px;
}

.search-brand {
    position: relative;
}

.right-side-form.under-launch-form input:focus,
.right-side-form.under-launch-form select:focus {
    box-shadow: unset;
}

.marketplace-filter-right {
    display: flex;
    align-items: center;
}

.marketplace-filter-right .css-1jqq78o-placeholder {
    font-size: 14px;
    color: #1F253C !important;
}

.marketplace-filter-right .css-13cymwt-control {
    border-radius: 24px;
    border: 1px solid #1F253C;
    box-shadow: 0px 0px 6px 0px #FFF;
    padding: 2px;
    background: #F1F7FF;
    margin: 0 18px 0 0;
}

.marketplace-filter-right .css-t3ipsp-control:hover {
    border-color: #1F253C;
}

.marketplace-filter-right .css-t3ipsp-control {
    border-radius: 24px;
    border: 1px solid #1F253C;
    box-shadow: 0px 0px 6px 0px #FFF;
    padding: 2px;
    background: #F1F7FF;
    margin: 0 18px 0 0;

}

.marketplace-filter-right .css-1u9des2-indicatorSeparator {
    background-color: transparent;
}

.marketplace-filter-right .css-tj5bde-Svg {
    fill: #111111;
}

.marketplace-listing {
    padding: 30px 0;
}

.product-detail-top {
    display: flex;
    align-items: center;
}

.product-detail h5 {
    color: #0D132C;
    font-size: 20px;
}

.product-detail p {
    color: #606779;
    font-weight: 400;
    padding: 5px 0;
}

.product-detail h5 {
    margin-bottom: 7px;
}

.product-detail p svg {
    margin-right: 10px;
}

.product-detail-top img {
    width: 173px;
    min-width: 173px;
    height: 173px;
    min-height: 173px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 25px;
}

.multiselector-area .search-wrapper.searchWrapper {
    border-radius: 24px;
    border: 1px solid #F1F7FF;
    background: #E8F0F8;
    padding: 11px 44px 11px 14px;
}

.multiselector-area .chip {
    align-items: center;
    background: #1037FD;
    border-radius: 11px;
    color: #fff;
    display: inline-flex;
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 2px;
    margin-right: 5px;
    padding: 0 6px;
    height: 27px;
}

.multiselector-area .closeIcon {
    margin-bottom: 0;
}

button.add-btn {
    color: #1037FD;
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    display: flex;
    margin: 0 0 0 auto;
}

.product-info {
    border-radius: 8px;
    background: #E8F0F8;
    display: flex;
    justify-content: space-around;
    padding: 30px;
    margin: 30px 10px 10px;
}

.product-info-inner {
    text-align: center;
}

.product-info-inner {
    text-align: center;
    border-left: 1px dashed #1037FD;
    padding: 30px 0;
}

.product-info-inner.border-remove {
    border: none;
}

.product-info-inner h3 {
    color: #0D132C;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 3px;
}

.product-info-inner p {
    color: #1F253C;
}

.product-images-bottom .col-md-4 {
    padding: 2px;
}

.product-images-bottom {
    margin: 0 0 5px;
}

.product-modal .modal-header {
    border: none;
    padding-bottom: 0;
}

.product-modal .modal-body {
    padding-top: 0;
}

.trash-area i.fa.fa-trash {
    position: absolute;
    right: 0;
    top: 16px;
    cursor: pointer;
    color: red;
    font-size: 18px;
}

.trash-area {
    position: relative;
}

.loader {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    display: inline-block;
    border-top: 6px solid #FFF;
    border-right: 6px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    border-left: 6px solid #1037FD;
    border-bottom: 6px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-outer {
    background: #000000c4;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 9;
}
.social-media-icon {
    width: 19px;
    cursor: pointer;
}

.swal2-popup.swal2-modal {
    border-radius: 30px;
}

.swal2-icon.swal2-error.swal2-icon-show {
    display: flex;
    justify-content: center;
    margin: 33px auto 15px;
}

h2#swal2-title {
    padding-top: 10px;
    color: #171a1c;
    font-size: 25px;
}

button.swal2-confirm.swal2-styled {
    padding: 9px 38px;
    border-radius: 130px;
    background-color: #1037FD;
}

div:where(.swal2-icon).swal2-error {
    border-color: #f27474;
    color: #f27474;
    border: 4px solid #f2747454;
}

.swal2-icon.swal2-success.swal2-icon-show {
    display: flex;
    justify-content: center;
    margin: 40px auto 20px;
}

.marketplace-section h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
}

.creater-profile h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
}

.creater-profile {
    background: #1037fd;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.creater-content {
    border-radius: 12px;
    background: #F1F7FF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 20px;
    align-items: center;
}

.craeter-profile-content {
    margin-top: -90px;
    padding-bottom: 50px;
}

.creater-img img {
    width: 250px;
    min-width: 250px;
    height: 250px;
    min-height: 250px;
    object-fit: cover;
    border-radius: 8px;
}

.creater-img {
    margin-right: 25px;
}
.creater-detail-top-left ul li a {
    text-decoration: none;
}
.bi.bi-link-45deg {
    background: #d9d9d9;
    padding: 3px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.creater-detail-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.creater-detail-top-left ul {
    display: flex;
    margin-left: 12px;
    align-items: center;
}

.creater-detail-top-left {
    display: flex;
    align-items: center;
}

.creater-detail-top-left h6 {
    color: #0D132C;
    font-size: 20px;
    font-weight: 500;
}

.creater-detail-top-left span {
    border-radius: 115px;
    border: 1px solid #F1F7FF;
    background: #DDEAFD;
    color: #1037FD;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 17px;
    margin: 0 25px;
}

.creater-detail-top-left ul li {
    list-style: none;
    margin: 0 8px;
}

.creater-detail-top svg {
    cursor: pointer;
}

.creater-detail p {
    color: #0D132C;
    padding: 18px 0;
}

ul.creater-info li {
    list-style: none;
    margin: 0 40px 0 0;
    display: flex;
    align-items: center;
    color: #606779;
}

ul.creater-info {
    display: flex;
    align-items: center;
}

ul.creater-info li svg {
    margin-right: 6px;
}

.creater-detail h5 {
    color: #606779;
    font-size: 16px;
    font-weight: 400;
    padding: 23px 0 12px 0;
}

.work-industries p {
    border-radius: 24px;
    border: 1px solid #F1F7FF;
    background: #DDEAFD;
    color: #1037fd;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 30px;
    margin: 0 6px 4px 0;
}

.work-industries {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.creater-profile-outer {
    min-height: 770px;
}

.coming-soon {
    padding-top: 400px;
}

.upload-img.main {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    position: relative;
}

.upload-img img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    margin: 0;
    border-radius: 15px;
    object-fit: cover;
}

.upload-img.main input {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0;
    left: 0;
    opacity: 0;
}

.login-section.creator-register-section.creator-registor {
    position: relative;
}

/* .left-side-img.register-left-side {
    position: absolute;
    bottom: 0;
    width: 50%;
} */
.login-section.creator-register-section.creator-registor .right-side-form {
    padding: 30px 50px;
}

.outer-div {
    min-height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 120px;
    background: #fff6f6;
    padding: 0 44px 0 12px !important;
    border-left: 1px solid #80808057;
    border-radius: 13px;
}

.outer-div::-webkit-scrollbar {
    width: 3px;
}

.outer-div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.outer-div::-webkit-scrollbar-thumb {
    background-color: #1037fd69;
}

img.arrow-icon {
    margin: 0;
    position: absolute;
    right: 17px;
    bottom: 18px;
    width: 17px;
}

.profile-data {
    border-radius: 34px;
    border: 2px solid #c2c2c2;
    background: lightgray;
    width: 46px;
    height: 46px;
    margin-left: 20px;
}

.under-drop-down .dropdown-menu.show {
    padding: 10px;
    border-radius: 12px;
}

.logout.dropdown-item {
    background: red;
    color: #fff;
    font-weight: 600;
    border-radius: 33px;
    text-align: center;
    margin-top: 7px;
}

header button.navbar-toggler.collapsed, .navbar-toggler:focus, button.navbar-toggler {
    border: 1px solid #ffffff !important;
    padding: 4px 4px !important;
    box-shadow: unset;
    opacity: 1;
    font-size: 15px;
}

header .navbar-toggler-icon {
    filter: invert(1);
}

.pagination li a {
    background: #F1F7FF;
    height: 45px;
    display: inline-block;
    width: 45px;
    border-radius: 42px;
    color: #1037fd;
    text-decoration: none;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 9px;
}

.pagination li.active a {
    background: #1037fd;
    color: #fff;
}

.swal2-icon {
    /* align-items: center; */
    margin: 0 auto;
}

.swal2-confirm.swal2-styled.swal2-default-outline {
    background: #1037FD !important;
}

.swal2-cancel.swal2-styled.swal2-default-outline {
    background: #504e4e !important;
    border-radius: 42px;
    border: none;
}

.text-danger {
    font-size: 13px;
    color: red !important;
    display: inline-block;
    margin: 6px 0 0 0;
    font-weight: 500;
}

textarea {
    border-radius: 12px;
    height: 60px !important;
}

.back-btn.d-block {
    cursor: pointer;
}

.scroll-div-form {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    padding: 0 20px 0 0;
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background: #b6b6b6;
}

::-webkit-scrollbar-thumb {
    background-color: #1037fd;
    /* outline: 1px solid slategrey; */
}
.creater-detail {
    width: 100%;
}
.right-side-form.edit-profile-page {
    background: #1037fd;
}
.main-form-edit {
    background: #fff;
    padding: 22px 32px;
    border-radius: 22px;
}
.marketplace-filter-right.main-right {
    display: unset;
}
.marketplace-filter-right.main-right .css-13cymwt-control, .marketplace-filter-right.main-right .css-t3ipsp-control {
    width: fit-content;
}
.marketplace-filter-right.main-right .css-1nmdiq5-menu {
    width: fit-content;
}
  ul.optionContainer li {
    font-size: 13px;
}
.highlightOption {
    background: #1037fd;
}
.multiSelectContainer li:hover {
    background: #1037fd;
    color: #fff;
    cursor: pointer;
}
.have-account-text {
    font-size: 16px;
    margin-top: 23px;
}
.have-account-text a {
    color: #1037fd;
    font-weight: 700;
    margin-left: 9px;
    cursor: pointer;
}
.contact-box {
    border-radius: 12px;
    background: #F1F7FF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    padding: 26px;
    text-align: center;
    height: 100%;
}
.contact-box img {
    margin-bottom: 16px;
}
.contact-box h6 {
    color: #0D132C;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.contact-box p {
    color: #606779;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 9px 0;
}
.contact-box a {
    color: #1037FD;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
}
header .navbar-brand {
    cursor: pointer;
}
.swal2-icon.swal2-warning.swal2-icon-show {
    margin-top: 30px;
}
.home-contact-inner {
    background-image: url("../images/home-contact-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 470px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-position: center;
    padding: 0 20px;
}
.home-contact-inner h3 {
    color: #fff;
    font-size: 40px;
    text-align: center;
    font-weight: 700;
}
.home-contact-inner a {
    border-radius: 214px;
    border: 1px solid #F1F7FF;
    background: #DDEAFD !important;
    box-shadow: 0px 0px 6px 0px #FFF;
    color: #1037fd !important;
    font-weight: 600;
    margin-top: 30px;
    padding: 9px 32px;
    font-size: 18px;
    text-decoration: unset;
}
.collabrate-area {
    padding: 90px 0 50px;
}
.collabrate-box {
    border-radius: 12px;
    box-shadow: 0px 0px 8px 0px #8DBDFF;
    background: #fff;
    overflow: hidden;
    cursor: pointer;
}
.collabrate-box img {
    height: 300px;
    min-height: 300px;
    width: 100%;
    object-fit: cover;
}
.collabrate-content {
    padding: 15px;
}
.collabrate-content h6 {
    color: #0D132C;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
}
.collabrate-content p {
    color: #1F253C;
}
.collabrate-inner .swiper-wrapper {
    margin: 0 0 20px 0;
    padding-top: 70px;
}
.collabrate-inner {
    position: relative;
}
.collabrate-inner .swiper-button-next {
    position: absolute;
    top: 30px;
}
.collabrate-inner .swiper-button-prev {
    position: absolute;
    top: 30px;
    right: 70px !important;
    left: unset;
}
.about-content h2 {
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 21px;
}
.collabrate-inner .swiper-button-prev:after, .collabrate-inner .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    background: #1037fd;
    color: #fff;
    font-size: 12px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    min-width: 30px;
    font-weight: bolder;
}
.collabrate-inner .about-content {
    padding: 0;
    margin-bottom: -75px;
}
.popup-header {
    border-bottom: none;
    padding-bottom: 0;
}
.popup-inner-content video {
    width: 100%;
}
.collabrate-box-image svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.collabrate-box-image {
    position: relative;
}
/* ---Blogs Start--------------- */
.blog-detail-outer {
    padding: 100px 0 50px;
}
header.blog-header {
    background: #1037FD;
}
.blog-detail-outer .wrapper .row {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 27px;
}
.post.post-box {
    border: 1px solid #80808040;
    padding: 17px;
    border-radius: 19px;
    display: flex;
    flex-wrap: wrap;
    min-height: 533px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.post.post-box img {
    border-radius: 15px;
    width: 302px;
    width: 100%;
    height: 26vh;
    object-fit: cover;
    margin-bottom: 20px;
}
.post.post-box h2 {
    font-size: 25px;
    margin-bottom: 10px;
}
.post.post-box p {
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
/* ----Tablet---- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .blog-detail-outer .wrapper .row {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

}
/* ----Mobile---- */
@media only screen and (max-device-width : 767px) {
    .blog-detail-outer .wrapper .row {
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 15px;
    }

}
/* ------Blogs End------------ */
@media (max-width: 1700px) {
    .scroll-div-form {
        height: 88vh;
    }
}

@media (max-width: 1600px) {
    .assets-1 {
        right: 0;
        top: 25%;
        width: 140px;
    }

    .coming-soon h1 {
        font-size: 60px;
    }

    .coming-soon h2 {
        font-size: 28px;
    }

    .instagram-icon {
        position: absolute;
        left: 0;
        top: 20%;
    }
}

@media (max-width: 1500px) {
    .scroll-div-form {
        height: 73vh;
    }
}
@media (max-width: 1320px) {
    .left-side-img {
        background: #1037fd;
    }
    .login-section.creator-register-section.creator-registor {
        height: 93vh;
    }
}

@media (max-width: 1200px) {
    .home-contact-inner h3 br {
        display: none;
    }
    .login-section.creator-register-section.creator-registor {
        height: 100%;
    }
    .contact-box h6 {
        font-size: 24px;
    }
    .contact-box {
        padding: 15px;
    }
    .contact-box a {
        font-size: 17px;
    }
    .register-text {
        font-size: 29px;
    }
    .scroll-div-form {
        height: 57vh;
    }
    .creator-bg h1 {
        font-size: 56px;
    }
    .community-section {
        padding: 61px 0 !important;
    }
    .performace-inner h4 {
        font-size: 30px;
    }

    .performace-inner h4 {
        font-size: 35px;
    }

    .under-cmny h3 {
        font-size: 26px;
        line-height: 30px;
    }

    .under-cmny p {
        font-size: 16px;
        line-height: 22px;
    }

    .under-cmny h3 span {
        font-size: 26px;
        line-height: normal;
    }

    .hero-content h1 {
        font-size: 60px;
    }

    .assets-2 {
        top: 32%;
        left: 20px;
        width: 130px;
    }

    .join-text-under {
        font-size: 30px;
        line-height: normal;
    }

    .community-section h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .join-text {
        font-size: 35px;
        line-height: normal;
    }
}
@media (max-width: 1024px) {
    .right-side-form {
        padding: 24px 30px;
    }
    .community-section h2, .join-text-under, .join-text {
        font-size: 23px;
    }
    .hero-section {
        height: 66vh;
    }
    .about-content h2 span {
        margin: 0;
    }
    .social-section {
        padding: 110px 0;
    }
    .creator-bg {
        padding: 98px 0 395px 0;
    }
    .under-cmny {
        padding: 11px;
    }
    .register-text {
        font-size: 20px;
    }
    .login-section.creator-register-section.creator-registor .right-side-form {
        padding: 30px 0;
    }
    .scroll-div-form {
        height: 50vh;
    }

    .eye-icon {
        top: 38px;
        right: 14px;
        width: 14px;
    }
    .performace-inner h4 {
        font-size: 24px;
    }
    .creator-bg h1 {
        font-size: 39px;
    }
    section.creator-bg h3 {
        font-size: 30px;
    }
    .hero-content p {
        font-size: 17px;
    }
    .about-section {
        padding: 40px 0;
    }
    .scroll-icon {
        width: 69px;
    }
    .hero-content h1, .hero-content h1 span {
        font-size: 41px;
        line-height: normal;
    }
    .marketplace-section {
        height: 248px;
    }
    .marketplace-listing {
        padding: 0;
    }
}

@media (max-width: 991px) {
    .home-contact-inner h3 {
        font-size: 30px;
    }
    .home-contact-inner {
        height: 300px;
        border-radius: 50px;
    }
    .creator-bg h1 {
        font-size: 45px;
    }
    ul.creater-info li {
        align-items: flex-start;
        flex-wrap: nowrap;
    }
    .product-modal .modal-dialog {
        max-width: 840px !important;
    }
    .search-brand {
        margin-bottom: 14px;
    }
    .scroll-div-form {
        height: 100%;
    }
    .left-side-img {
        display: none;
    }
    .right-side-form {
        padding: 34px 90px;
    }

    section.creator-bg h3 {
        font-size: 30px;
    }

    header .navbar-nav-scroll {
        overflow-y: unset;
        min-height: fit-content;
    }

    header .navbar-collapse {
        background: #2371e5;
        padding: 10px 20px 20px;
        border-radius: 20px;
    }

    header .nav-link,
    header .nav-link:hover {
        text-align: left;
    }

    .footer-content a {
        font-size: 15px;
        margin: 0 6px;
    }

    .footer-logo img {
        width: 100px;
    }

    .social-section {
        padding: 130px 0;
    }

    .social-section h2 {
        font-size: 34px;
        line-height: 42px;
    }

    .social-section h2 {
        padding: 0;
    }

    .facebook-icon {
        right: 20px;
        width: 100px;
    }

    .youtube-icon {
        bottom: 10px;
        width: 70px;
    }

    .instagram-icon {
        left: 20px;
        top: 0px;
        width: 100px;
    }

    .about-content h2 span {
        margin: 1px;
    }

    .about-content {
        padding: 0 0 30px 0;
    }

    .row-direction {
        flex-direction: column-reverse;
    }

    .hero-section {
        height: 100%;
        padding: 90px 0 160px;
    }

    .coming-soon img {
        width: 72px;
    }

    .coming-soon h1 {
        font-size: 40px;
    }

    .coming-soon h2 {
        font-size: 23px;
        margin: 12px 0;
    }

    .right-side-form.under-launch-form {
        padding: 21px;
    }

    .right-side-form .form-label {
        line-height: 22px;
    }
}

@media (max-width: 767px) {
    .community-section {
        padding: 40px 0;
    }
    .contact-box {
        height: unset;
        margin-bottom: 15px;
    }
    .craeter-profile-content {
        margin-bottom: 15px;
    }
    .craeter-profile-content .mt-4.col-md-6 {
        margin-top: 0 !important;
    }
    .creater-img img {
        width: 160px;
        min-width: 160px;
        height: 160px;
        min-height: 160px;
    }
    .creater-profile {
        height: 230px;
    }
    ul.creater-info {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        align-content: flex-start;
    }
    ul.creater-info li {
        margin-bottom: 11px;
    }
    .creater-img {
        margin-right: 0;
        margin-bottom: 31px;
    }
    .creater-content {
        flex-direction: column;
    }
    .marketplace-section h2 {
        font-size: 29px;
    }
    .marketplace-section {
        height: 214px;
    }
    .creator-bg {
        padding: 103px 0 419px 0;
    }
    .community-section h2 {
        font-size: 25px;
        line-height: 34px;
    }

    .join-text {
        font-size: 28px;
    }

    .join-text-under {
        font-size: 25px;
    }


    .footer-content {
        text-align: left !important;
        margin: 20px 0;
    }

    .footer-content a {
        font-size: 15px;
        margin: 0 26px 0 0;
    }

    .footer-content.social-media {
        margin: 0;
    }

    .social-section {
        padding: 100px 0;
    }

    .social-section h2 {
        font-size: 28px;
        line-height: 35px;
    }

    .hero-img {
        margin-top: 20px;
    }

    .scroll-icon {
        width: 70px;
    }

    .hero-content p {
        font-size: 18px;
    }

    .hero-content h1 {
        font-size: 50px;
        line-height: 50px;
    }

    .hero-content h1 span {
        font-size: 60px;
        line-height: normal;
    }

    .coming-soon {
        text-align: center;
        padding: 0 0 20px 0;
    }

    .coming-soon span:after {
        display: none;
    }

    .coming-soon span {
        padding: 0;
    }

    .right-side-form.under-launch-form {
        padding: 33px;
    }
}

@media (max-width: 575px) {
    .home-contact-inner h3 {
        font-size: 27px;
    }
    .post.post-box img {
        margin-bottom: 14px;
    }
    .home-contact-inner a {
        padding: 8px 24px;
        font-size: 16px;
    }
    .right-side-form.register-form {
        padding: 30px 10px !important;
    }
    .product-detail p {
        font-size: 14px;
    }
    .product-detail-top img {
        width: 91px;
        min-width: 91px;
        height: 91px;
        min-height: 91px;
    }
    .product-info-inner {
        padding: 21px 4px;
        height: 100%;
    }
    .product-info-inner h3 {
        font-size: 21px;
    }
    .product-info-inner p {
        font-size: 14px;
    }
    .product-info {
        padding: 0;
    }
    .product-images-bottom {
        text-align: center;
    }
    .assets-2, .assets-1{
        display: none;
    }
    .right-side-form {
        padding: 34px 33px;
    }

    .about-content h2 {
        font-size: 30px;
    }

    .about-content h2 span {
        font-size: 32px;
        line-height: 40px;
    }
}

@media (max-width: 480px) {
    .collabrate-area {
        padding: 50px 0;
    }
    .collabrate-inner .swiper-button-prev {
        right: 40px !important;
    }
    .collabrate-inner .swiper-button-prev, .collabrate-inner .swiper-button-next {
        top: 35px;
    }
    .collabrate-inner .swiper-button-prev:after, .collabrate-inner .swiper-button-next:after {
        font-size: 10px;
        width: 25px;
        height: 25px;
        min-width: 25px;
    }
    .home-contact-inner h3 {
        font-size: 22px;
    }
    .home-contact-inner {
        height: 250px;
        border-radius: 30px;
    }
    .creater-profile h2 {
        color: #fff;
        font-size: 29px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .contact-box p {
        font-size: 14px;
        line-height: 20px;
    }
    .contact-box img {
        width: 30px;
    }
    .contact-box h6 {                                     
        font-size: 20px;
    }
    .creater-detail p {
        font-size: 14px;
    }
    .register-text {
        font-size: 30px;
    }
    .filter-box-inner ul .nav-link {
        padding: 8px 14px;
    }
    .filter-box-inner {
        padding: 8px;
    }
    .right-side-form {
        padding: 34px 2px;
    }

    .under-cmny {
        padding: 20px;
    }

    .under-cmny h3 span,
    .under-cmny h3 {
        font-size: 19px;
        line-height: 25px;
        margin-bottom: 7px;
    }

    .under-cmny p {
        font-size: 14px;
        line-height: 20px;
    }

    .under-cmny img {
        height: 200px;
    }

    .community-section h2 {
        font-size: 20px;
        line-height: 27px;
    }

    .join-text {
        font-size: 22px;
    }

    .join-text-under {
        font-size: 20px;
    }
    .creator-bg {
        padding: 83px 0 219px 0;
    }
    section.creator-bg h3 {
        font-size: 18px;
    }
    .eye-icon {
        top: 49px;
    }

    .assets-1,
    .assets-2 {
        display: none;
    }

    footer {
        padding: 30px 0;
    }

    .footer-content a {
        font-size: 14px;
        margin: 0 10px 0 0;
    }

    .facebook-icon {
        right: 10px;
        width: 70px;
    }

    .youtube-icon {
        width: 50px;
    }

    .social-section {
        padding: 70px 0;
    }

    .instagram-icon {
        width: 70px;
    }

    .social-section h2 {
        font-size: 19px;
        line-height: 26px;
    }

    .about-section {
        padding: 50px 0;
    }

    .creator-box img {
        width: 45px;
        min-width: 45px;
    }

    .about-content span {
        font-size: 14px;
    }

    .creator-box p {
        font-size: 14px;
    }

    .creator-box h4 {
        font-size: 18px;
    }

    .about-content h2 {
        font-size: 25px;
    }

    .about-content h2 span {
        font-size: 26px;
        line-height: 33px;
    }

    .scroll-icon {
        width: 50px;
    }

    .hero-content p {
        font-size: 15px;
        line-height: 21px;
    }

    .hero-section {
        padding: 90px 0 100px;
    }

    .hero-content h1 {
        font-size: 32px;
    }

    .explore-btns.btn.btn-primary {
        padding: 8px 14px;
    }

    .hero-content h1 span {
        font-size: 40px;
    }

    .trash-area i.fa.fa-trash {
        right: -15px;
        bottom: 18px;
        cursor: pointer;
        color: red;
        font-size: 17px;
    }

    .coming-soon {
        padding: 0;
    }

    .coming-soon h1 {
        font-size: 30px;
        letter-spacing: 0;
    }

    .coming-soon h2 {
        letter-spacing: 0;
    }

    .right-side-form .nav-tabs .nav-link.active,
    .right-side-form .nav-tabs .nav-link {
        padding: 9px 18px;
    }

    .right-side-form.under-launch-form {
        padding: 18px;
        border-radius: 18px;
    }

    .launch-section {
        padding: 11px 0;
    }

    .coming-soon span {
        margin: 16px 0px 6px 0;
    }

    .coming-soon h2 {
        font-size: 20px;
        margin: 7px 0 15px;
    }

    .right-side-form h1 {
        line-height: 25px;
        font-size: 21px;
    }
    .creator-bg h1 {
        font-size: 24px;
    }
}
/* ---Single Blog------ */
.single-blog-section {
    padding: 7em 0 3em;
}
.single-blog-banner img {
    height: 54vh;
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 20px;
    filter: brightness(0.3);
}
.blog-title {
    position: absolute;
    bottom: 6%;
    left: 0;
    font-size: 29px;
    color: #fff;
    padding: 0 40px;
}
.single-blog-content {
    padding: 21px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: 19px;
}
.blog-number-list {
    padding: 0;
    padding-left: 15px;
    list-style: auto;
}